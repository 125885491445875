// NODE MODULES
import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import Fade from 'react-reveal/Fade';

// COMPONENTS
import Layout from "../layouts/pl";

// ICONS
import { FaPhone } from 'react-icons/fa';
import { FaFacebookMessenger } from 'react-icons/fa';
import { FaFacebookSquare } from 'react-icons/fa';
import { FaEnvelopeOpenText } from 'react-icons/fa';
import { FaWhatsapp } from 'react-icons/fa';
import { TiLocationOutline } from 'react-icons/ti';
import { SiGooglemaps } from 'react-icons/si';


const Kontakt = (props) => {
  const data = useStaticQuery(graphql` 
  query {
    desktopImage: file(relativePath: { eq: "images/photos/okolica1.jpg" }) {
          childImageSharp {
              fluid(quality: 100){
              ...GatsbyImageSharpFluid
              }
          }   
      }
      mobileImage: file(relativePath: { eq: "images/photos/okolica1-mobile.jpg" }) {
        childImageSharp {
            fluid(quality: 50){
            ...GatsbyImageSharpFluid
            }
        }   
    }
  }`);

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ];

  return (
    <Layout location={props.location}>
      <section className="kontakt">
        <div className="content-container">

          <Fade up>
            <section className="facts">
              <h1>KONTAKT</h1>
              
              <div style={{ marginBottom: 70 }}>
                <span>
                  <p>Apartament Amber 1</p>
                  <TiLocationOutline />

                  <p>ul. Wolności 38 b</p>
                  <p>72-420 Dziwnówek</p>

                </span>
                <span>
                  <p>Apartament Amber 2</p>
                  <TiLocationOutline />
                  <p>ul. Wolności 40 a</p>
                  <p>72-420 Dziwnówek</p>
                </span>
              </div>
              <div style={{ marginBottom: 70 }}>
                <span>
                  <p>Apartament Amber 3</p>
                  <TiLocationOutline />
                  <p>ul. Spadochroniarzy Polskich 10 a</p>
                  <p>72-420 Dziwnów</p>
                </span>
              </div>
            </section>
          </Fade>


          <div className="kontakt-details">
            <span />
            <p><a href="tel:+48 663742379">+48 663 742 379</a></p>
            <p><a href="mailto:apartamenty.amber@wp.pl">apartamenty.amber@wp.pl</a></p>
            <span />
          </div>

          <span className="icons" style={{ marginBottom: 70 }}>
            <a href="tel:+48663742379" target="_blank" rel="noreferrer"><FaPhone /></a>
            <a href="https://api.whatsapp.com/send?phone=48663742379" target="_blank" rel="noreferrer"><FaWhatsapp /></a>
            <a href="mailto:apartamenty.amber@wp.pl" target="_blank" rel="noreferrer"><FaEnvelopeOpenText /></a>
            <a href="https://www.facebook.com/apartamenty.amber.dziwnowek" target="_blank" rel="noreferrer"><FaFacebookSquare /></a>
            <a href="https://www.facebook.com/apartamenty.amber.dziwnowek" target="_blank" rel="noreferrer"><FaFacebookMessenger /></a>
            <a href="https://g.page/apartamenty-amber?share" target="_blank" rel="noreferrer"><SiGooglemaps /></a>
          </span>

        </div>
      </section>
    </Layout>
  )
}


export default Kontakt
